<template>
  <div>
    <v-alert
      :value="compAlertShow"
      :color="compAlert.type.color"
      :dark="compAlert.type.dark"
      :border="compAlert.type.border"
      transition="scale-transition"
      dismissible
    >
      {{ $t(compAlert.text) }}
    </v-alert>
    <!-- icon="mdi-home" -->
  </div>
</template>

<script>
import alertMixin from "@/_mixin/alertMixin";

export default {
  name: "AlertComp",
  mixins: [alertMixin],
};
</script>

<style lang="scss" scoped></style>
