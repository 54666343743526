<template>
  <v-footer
    color="deep-purple darken-4"
    active
    app
    :height="$vuetify.breakpoint.smAndUp ? 36 : 28"
  >
    <div class="white--text">{{ compVersion }}</div>
    <v-spacer></v-spacer>
    <div class="white--text">
      {{ compName }} &copy; {{ new Date().getFullYear() }}
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComp",
  computed: {
    compVersion() {
      return "v " + process.env.VUE_APP_VERSION;
    },
    compName() {
      return process.env.VUE_APP_NAME;
    },
  },
};
</script>

<style scoped></style>
