import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./_router";
import store from "./_store";
import i18n from "./_locales/i18n";
import Plugin from "./_plugins/axios";
import vuetify from "./_plugins/vuetify";

// agregamos mixins globales - snackbar y loader
import loaderMixin from "@/_mixin/loaderMixin";
import snackbarMixin from "@/_mixin/snackbarMixin";
import alertMixin from "@/_mixin/alertMixin";

Vue.mixin(loaderMixin);
Vue.mixin(snackbarMixin);
Vue.mixin(alertMixin);

Vue.use(Plugin);

Vue.config.productionTip = false;

//import de auth 0
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENTID;
import { Auth0Plugin } from "./_auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
