<template>
  <v-container fluid class="px-1 px-md-2">
    <LoaderComp />
    <NotificationComp />
    <AlertComp class="mt-15" />
    <navbar-comp></navbar-comp>
    <div class="mt-15">
      <router-view></router-view>
    </div>
    <FooterComp />
  </v-container>
</template>

<script>
import NavbarComp from "../components/common/NavbarComp";
import LoaderComp from "../components/common/LoaderComp";
import NotificationComp from "../components/common/NotificationComp";
import AlertComp from "../components/common/AlertComp";
import FooterComp from "../components/common/FooterComp";
export default {
  name: "home",
  components: {
    NavbarComp,
    LoaderComp,
    NotificationComp,
    AlertComp,
    FooterComp,
  },
};
</script>
