const state = {
  overlay: false,
};

const mutations = {
  SET_OVERLAY(state, data) {
    state.overlay = data;
  },
};

const actions = {};

const getters = {
  gtrOverlay: (state) => state.overlay,
};

export const loaderStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
