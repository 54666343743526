let config;

//middleware para los servicios

if (process.env.NODE_ENV === "production") {
  config = {
    apiUrl: process.env.VUE_APP_PRODUCTION_API,
    timeoutDuration: 1000,
    someOtherProps: "abc",
  };
} else {
  config = {
    apiUrl: process.env.VUE_APP_DEV_API,
    timeoutDuration: 1000,
    someOtherProps: "abc",
  };
}

export { config };
