<template>
  <div>
    <v-snackbar
      v-model="compShow"
      :color="compSnackbar.type.color"
      :top="compSnackbar.type.top"
      :timeout="compSnackbar.timeout"
      :dark="compSnackbar.type.dark"
      :centered="compSnackbar.type.centered"
    >
      {{ $t(compSnackbar.text) }}

      <!--  <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template> -->
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "NotificationComp",
};
</script>

<style lang="scss" scoped></style>
