/* eslint-disable no-unused-vars */
const localStName = process.env.VUE_APP_LOCALST;
import { userSrv } from "@/_services";

let usuarioDatos = JSON.parse(localStorage.getItem(localStName)) || null;

const state = {
  usuario: {
    data: usuarioDatos,
    loading: false,
  },
};
const mutations = {
  SET_REMOVE_USER_STORAGE(state) {
    localStorage.removeItem(localStName);
    state.usuario.data = null;
  },
  SET_USER_STORAGE(state, datos) {
    state.usuario.data = datos;
    const data = JSON.stringify(datos);
    localStorage.setItem(localStName, data);
  },
};
const actions = {
  /* funcion para guardar los datos asincronamente en el localStorage */
  async setUserDataLocalStorage({ commit }, datos) {
    const data = await JSON.stringify(datos);
    await localStorage.setItem(localStName, data);
    return true;
  },

  /* funcion para logear al usuario */
  setLoginUser({ commit }, datos) {
    const promesa = new Promise((resolve) => {
      userSrv
        .login(datos)
        .then(async (res) => {
          if (res.status === 200) {
            commit("SET_USER_STORAGE", res.data);
            setTimeout(() => {
              return resolve(true);
            }, 400);
          }

          if (
            Object.prototype.hasOwnProperty.call(res, "response") &&
            res.response.status === 422
          ) {
            // console.log(res.response.data);
            //return res.response.data;
            return resolve(false);
          }

          if (
            Object.prototype.hasOwnProperty.call(res, "response") &&
            res.response.status === 401
          ) {
            commit("SET_REMOVE_USER_STORAGE");
            //console.log("no estas autorizado");
            return resolve(false);
          }
          // console.log(res);
          return resolve(res);
        })
        .catch((err) => {
          return resolve(false);
        });
    });
    return promesa;
  },

  setLogOut({ commit }, datos) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(localStName);
      return resolve(true);
    });
  },
};

const getters = {
  gtrUserData: (state) => {
    const tmp = state.usuario.data;

    if (!tmp) {
      return null;
    }

    const control = Object.prototype.hasOwnProperty.call(tmp, "user");
    return control ? state.usuario.data.user : null;
  },
};

export const userStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
