import { mapGetters, mapMutations } from "vuex";

const alertMixin = {
  methods: {
    ...mapMutations("alertStore", ["SET_ALERT", "SET_ALERT_SHOW"]),
    mixShowAlert(text, type = "success") {
      const tmp = {
        text,
        type,
      };
      setTimeout(() => {
        this.SET_ALERT(tmp);
      }, 250);
    },
  },
  computed: {
    ...mapGetters("alertStore", ["gtrAlert"]),
    compAlert() {
      return this.gtrAlert;
    },
    compAlertShow: {
      get() {
        return this.gtrAlert.show;
      },
      set(value) {
        this.SET_ALERT_SHOW(value);
      },
    },
  },
  destroyed() {
    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
      if (this.gtrAlert.show) {
        setTimeout(() => {
          this.SET_ALERT_SHOW(false);
        }, 100);
      }
    } catch (error) {}
  },
};

export default alertMixin;
