import Vue from "vue";
import Vuex from "vuex";
import { userStore } from "./user.store";
import { loaderStore } from "./loader.store";
import { snackbarStore } from "./snackbar.store";
import { alertStore } from "./alert.store";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    userStore,
    loaderStore,
    snackbarStore,
    alertStore,
  },
});
