import { mapGetters, mapMutations, mapActions } from "vuex";

const userMixin = {
  methods: {
    ...mapActions("userStore", ["setLoginUser", "setUserDataLocalStorage"]),
    ...mapMutations("userStore", [
      "SET_REMOVE_USER_STORAGE",
      "SET_USER_STORAGE",
    ]),
    async mixSetLoginUser(email, password) {
      const res = await this.setLoginUser({ email, password });
      return res;
    },
  },
  computed: {
    ...mapGetters("userStore", ["gtrUserData"]),
    compUserData() {
      return { ...this.gtrUserData };
    },
  },
};

export default userMixin;
