import Vue from "vue";

import { config } from "@/_config";
import { authHeader } from "@/_helpers";

export const doTestSrv = {
  getAllTests,
  getTest,
  checkTest,
  checkQuestion,
};

function getAllTests() {
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };

  const route = `${config.apiUrl}/getAllTests`;

  return Vue.axios
    .get(route, headers)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function getTest(id) {
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };

  const route = `${config.apiUrl}/getTest/${id}`;

  return Vue.axios
    .get(route, headers)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function checkTest(id) {
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };

  const route = `${config.apiUrl}/checkTest/${id}`;

  return Vue.axios
    .get(route, headers)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function checkQuestion(id) {
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };

  const route = `${config.apiUrl}/checkQuestion/${id}`;

  return Vue.axios
    .get(route, headers)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
}
