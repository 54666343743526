import { mapGetters, mapMutations } from "vuex";

const loaderMixin = {
  methods: {
    ...mapMutations("loaderStore", ["SET_OVERLAY"]),
    mixShowOverlay() {
      this.SET_OVERLAY(true);
    },

    mixHideOverlay() {
      this.SET_OVERLAY(false);
    },
  },
  computed: {
    ...mapGetters("loaderStore", ["gtrOverlay"]),
    compOverlay() {
      return this.gtrOverlay;
    },
  },
};

export default loaderMixin;
