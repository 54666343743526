import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import es from "vuetify/lib/locale/es";
import en from "vuetify/lib/locale/en";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {},
  },
  lang: {
    locales: { es, en },
    current: "es",
  },
});
