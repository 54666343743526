import { mapGetters, mapMutations } from "vuex";

const snackbarMixin = {
  methods: {
    ...mapMutations("snackbarStore", ["SET_SNACKBAR", "SET_SNACK_SHOW"]),
    // type -> success, error
    mixSetSnackbar(text, type = "default") {
      this.SET_SNACKBAR({ text, type });
    },
  },
  computed: {
    ...mapGetters("snackbarStore", ["gtrSnackbar"]),
    compSnackbar() {
      return { ...this.gtrSnackbar };
    },
    compShow: {
      get() {
        return this.gtrSnackbar.show;
      },
      set(value) {
        this.SET_SNACK_SHOW(value);
      },
    },
  },
};

export default snackbarMixin;
