<template>
  <div class="text-center">
    <v-overlay :value="compOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "LoaderComp",
};
</script>

<style lang="scss" scoped></style>
