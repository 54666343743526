import Vue from "vue";
import { config } from "@/_config";
import { authHeader } from "@/_helpers";

export const userSrv = {
  login,
  logout,
};

function login(datos) {
  const route = `${config.apiUrl}/auth/login`;
  const headers = {
    "Content-Type": "Application/json",
  };

  return Vue.axios
    .post(route, datos, headers)
    .then(function(response) {
      return response;
    })
    .catch(function(err) {
      console.log(err);

      /*  if (err.response) {
        // client received an error response (5xx, 4xx)
        console.log("error respuesta", err.response);
      } else if (err.request) {
        console.log("error request", err.request);
        throw new Error("err.respuesta");
        // client never received a response, or request never left
      } else {
        // anything else
        console.log("error otro", err);
      } */
      return err;
    });
}

function logout() {
  const route = `${config.apiUrl}/auth/logout`;
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };
  return Vue.axios
    .post(route, headers)
    .then(function(response) {
      return response;
    })
    .catch(function(err) {
      console.log(err);
    });
}
