const state = {
  snackbar: {
    text: "updated-success",
    show: false,
    timeout: 1500,
    type: {
      dark: true,
      color: "red",
      top: true,
      centered: true,
    },
  },

  type_success: {
    dark: true,
    color: "green",
    top: true,
    centered: true,
  },

  type_error: {
    dark: false,
    color: "red",
    top: true,
    centered: true,
  },

  type_default: {
    dark: true,
    top: true,
    centered: true,
  },
};

const mutations = {
  SET_SNACKBAR(state, data) {
    state.snackbar.text = data.text;

    switch (data.type) {
      case "success":
        state.snackbar.type = state.type_success;
        break;
      case "error":
        state.snackbar.type = state.type_error;
        break;
      default:
        state.snackbar.type = state.type_default;
        break;
    }
    state.snackbar.show = true;
  },

  SET_SNACK_SHOW(state, data) {
    state.snackbar.show = data;
  },
};

const actions = {};

const getters = {
  gtrSnackbar: (state) => state.snackbar,
};

export const snackbarStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
