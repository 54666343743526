<template>
  <div>
    <v-app-bar
      app
      color="deep-purple darken-4"
      dark
      :height="$vuetify.breakpoint.smAndUp ? 64 : 34"
    >
      <!--  <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title>{{ compName }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <router-link
        v-for="(link, index) in links"
        :key="index"
        tag="a"
        :class="btnClass"
        :to="link.url"
        :exact-active-class="'v-btn--active'"
      >
        {{ link.name }}
      </router-link>

      <router-link
        v-if="!compUserData"
        tag="a"
        :class="btnClass"
        to="/login"
        :exact-active-class="'v-btn--active'"
      >
        {{ $t("login") }}
      </router-link>

      <v-btn plain v-else @click="closeSession">
        {{ $t("logout") }}
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import userMixin from "@/_mixin/userMixin";

export default {
  name: "navbarComp",
  mixins: [userMixin],
  data() {
    return {
      btnClass:
        "ml-1 v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default",
      links: [
        { name: "home", url: "/", role: [] },
        { name: "Nuevo Test", url: "/new-test", role: [] },
      ],
    };
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect({ ui_locales: "es" });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    closeSession() {
      setTimeout(() => {
        this.SET_REMOVE_USER_STORAGE();
        this.$router.push("/login");
      }, 300);
    },
  },
  computed: {
    compName() {
      return process.env.VUE_APP_NAME;
    },
  },
  mounted() {
    this.$auth.auth0Client.options.ui_locales = "es";
    //console.log(this.$auth.auth0Client.options);
  },
};
</script>

<style lang="scss" scoped></style>
