import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

const localStName = process.env.VUE_APP_LOCALST;
const publicPages = ["/login"];

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        name: "Home",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/main/MainComp.vue"
          ),
      },
      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/About.vue"),
      },
      {
        path: "/new-test",
        name: "NewTest",
        component: () =>
          import(
            /* webpackChunkName: "TestNewMainComp" */ "@/components/test/TestMainComp.vue"
          ),
      },
      {
        path: "/edit-test/:id",
        name: "EditTest",
        component: () =>
          import(
            /* webpackChunkName: "TestEditMainComp" */ "@/components/test/TestMainComp.vue"
          ),
      },
      {
        path: "/do-test/:id",
        name: "DoTest",
        component: () =>
          import(
            /* webpackChunkName: "DoTestComp" */ "@/components/do-test/DoTestMainComp.vue"
          ),
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem(localStName)) || false;

  if (authRequired && !loggedIn) {
    return next("login");
  }

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 30);
});

export default router; /* "@/components/test/edit/TestEditMainComp.vue", */
