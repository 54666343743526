const state = {
  alert: {
    show: false,
    text: null,
    type: {
      color: "red",
      border: "top",
      dark: true,
    },
  },
  type_success: {
    color: "red",
    border: "top",
    dark: true,
  },
  type_error: {
    color: "pink",
    border: "top",
    dark: true,
  },
  type_warning: {
    color: "warning",
    border: "left",
    dark: true,
  },
  type_default: {
    color: "info",
    border: "left",
    dark: true,
  },
};

const mutations = {
  SET_ALERT(state, data) {
    state.alert.text = data.text;

    switch (data.type) {
      case "success":
        state.alert.type = state.type_success;
        break;
      case "error":
        state.alert.type = state.type_error;
        break;
      case "warning":
        state.alert.type = state.type_warning;
        break;
      default:
        state.alert.type = state.type_default;
        break;
    }
    state.alert.show = true;
  },

  SET_ALERT_SHOW(state, data) {
    state.alert.show = data;
  },
};

const actions = {};

const getters = {
  gtrAlert: (state) => state.alert,
};

export const alertStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
