import Vue from "vue";
import { config } from "@/_config";
import { authHeader } from "@/_helpers";

export const testSrv = {
  createTest,
  getTestById,
  updateTest,
};

function createTest(datos) {
  const route = `${config.apiUrl}/test`;
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };

  return Vue.axios
    .post(route, datos, headers)
    .then(function(response) {
      return response;
    })
    .catch(function(err) {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        console.log("error respuesta", err.response);
      } else if (err.request) {
        console.log("error request", err.request);
        throw new Error("err.respuesta");
        // client never received a response, or request never left
      } else {
        // anything else
        console.log("error otro", err);
      }
      return err;
    });
}

function getTestById(id) {
  const route = `${config.apiUrl}/test/${id}`;
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };

  return Vue.axios
    .get(route, headers)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateTest(data) {
  const route = `${config.apiUrl}/test/${data.id}`;
  const headers = {
    "content-Type": "Application/json",
    headers: authHeader(),
  };

  return Vue.axios
    .put(route, data, headers)
    .then(function(response) {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}
