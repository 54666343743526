export function authHeader() {
  const localStName = process.env.VUE_APP_LOCALST;
  // return authorization header with jwt token
  let data = JSON.parse(localStorage.getItem(localStName));

  if (data && data.access_token) {
    return { Authorization: "Bearer " + data.access_token };
  } else {
    return {};
  }
}
